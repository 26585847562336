<template>
  <div class="min-h-screen bg-purple-pattern text-white flex flex-col">
    <div class="bg-white text-black p-5 rounded-md shadow-md w-full xl:w-1/3 m-auto text-center">
      <div class="text-3xl font-pixel">Owner Controls</div>
      <hr class="my-5"/>
      <button class="btn" @click="startMainSale">Start Main Sale</button>
      <hr class="my-5"/>
      <form @submit.prevent="reveal">
        <input class="border border-gray-300 rounded-md p-4 mr-3 text-center" v-model="secret"/>
        <button class="btn">Reveal</button>
      </form>
      <hr class="my-5"/>
      <button class="btn" @click="withdraw">Withdraw</button>
      <hr class="my-5"/>
      <form @submit.prevent="openArena">
        <input class="border border-gray-300 rounded-md p-4 mr-3 text-center" v-model="value"/> ETH
        <button class="btn ml-5">Open Arena</button>
      </form>
      <hr class="my-5"/>
      <button class="btn" @click="timeToEat">Time to Eat</button>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Footer from '../components/Footer';

import { Ethereum } from '../js/ethereum.js';
import { notifyError, notifyTransaction } from '@/js/notifications';
import { ethers } from 'ethers';


export default {
  name: 'Owner',
  components: { Footer },
  data() {
    return {
      secret: '',
      ethereum: Ethereum.state,
    };
  },
  methods: {
    async startMainSale() {
      try {
        const tx = await Ethereum.contract.startMainSale();
        return notifyTransaction(tx);
      } catch (e) {
        console.error(e);
        notifyError('Something went wrong.');
      }
    },
    async reveal() {
      try {
        const tx = await Ethereum.contract.theGreatReveal(this.secret);
        return notifyTransaction(tx);
      } catch (e) {
        console.error(e);
        notifyError('Something went wrong.');
      }
    },
    async withdraw() {
      try {
        const tx = await Ethereum.contract.withdraw();
        return notifyTransaction(tx);
      } catch (e) {
        console.error(e);
        notifyError('Something went wrong.');
      }
    },
    async openArena() {
      try {
        const tx = await Ethereum.contract.openArena({ value: ethers.utils.parseEther(this.value) });
        return notifyTransaction(tx);
      } catch (e) {
        console.error(e);
        notifyError('Something went wrong.');
      }
    },
    async timeToEat() {
      try {
        const tx = await Ethereum.contract.timeToEat();
        return notifyTransaction(tx);
      } catch (e) {
        console.error(e);
        notifyError('Something went wrong.');
      }
    },
  }
};
</script>

<style lang="scss"></style>